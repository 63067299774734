
import { Component, Mixins, Watch } from '~/utility/pu-class-decorator'
import {
  FavouritesMachineInterpreter,
  getFavouritesMachine,
} from '~~/apps/favourites/favourites.machine'
import AuthMixin from '~~/mixins/AuthMixin'

@Component
export default class AppHeaderFavouritesCount extends Mixins(AuthMixin) {
  favouritesCount: number | null = null
  favMachine: FavouritesMachineInterpreter | null = null

  async authMounted() {
    this.favMachine = getFavouritesMachine(this.$i18n.locale).onTransition(
      (state: FavouritesMachineInterpreter['state']) => {
        this.favouritesCount = state.context.favourites.length
      },
    )
  }

  @Watch('isLoggingInSuccess')
  fetchLoggedInFavourites() {
    if (this.favMachine && this.isLoggingInSuccess) {
      this.favMachine.send('FETCH_ALL')
    }
  }

  @Watch('isLoggingOutSuccess')
  dropAllFavourites() {
    if (this.favMachine && this.isLoggingOutSuccess) {
      this.favMachine.send('CLEAR_ALL')
    }
  }
}
